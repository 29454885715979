import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { userStore } from '../store/user';
// 设置接口超时时间
axios.defaults.timeout = 60000;

// 请求地址，这里是动态赋值的的环境变量，下一篇会细讲，这里跳过
// @ts-ignore
axios.defaults.baseURL = import.meta.env.VITE_API_URL;

//http request 拦截器
axios.interceptors.request.use(
    config => {
        // 配置请求头
        config.headers = {
            //'Content-Type':'application/x-www-form-urlencoded',   // 传参方式表单
            'Content-Type': config.headers['Content-Type'] || 'application/json;charset=UTF-8',        // 传参方式json
            "Authorization": userStore().token
        };
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//http response 拦截器
axios.interceptors.response.use(
    response => {
        // 如果是文件下载，直接返回response
        console.warn(response)
        if (response.config.responseType === 'blob') {
            return response.data;
        }
        return response.data;
    },
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            return Promise.reject(response.data);
        } else {
            notify('网络连接异常,请稍后再试!', "error", 2000);
        }
    }
);

// 封装 GET POST 请求并导出
export function request(url = '', params = {}, type = 'POST', config = {}) {
    //设置 url params type 的默认值
    return new Promise((resolve, reject) => {
        let promise
        if (type.toUpperCase() === 'GET') {
            promise = axios({
                url,
                params,
                ...config
            })
        } else if (type.toUpperCase() === 'POST') {
            promise = axios({
                method: 'POST',
                url,
                data: params,
                ...config
            })
        }else if (type.toUpperCase() === 'PUT') {
            promise = axios({
                method: 'PUT',
                url,
                data: params,
                ...config
            })
        }else if (type.toUpperCase() === 'DELETE') {
            promise = axios({
                method: 'DELETE',
                url,
                data: params,
                ...config
            })
        }
        //处理返回
        promise.then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
