import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import Home from "@/views/home-page.vue";
import Profile from "@/views/profile-page.vue";
import LoginView from '@/views/login-form.vue';
import defaultLayout from "./layouts/side-nav-outer-toolbar.vue";
import simpleLayout from "./layouts/single-card.vue";
import { userStore} from "./store/user.js";
import {getUserInfo} from "./api/auth.js";
import {isNotEmpty} from "./utils/devextremeUtil.js";
const modules = import.meta.glob("./views/**/*.vue");


const router = new createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/formmain30003/details",
      name: "formmain30003Details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: loadView("erp/formmain30003/details")
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "用户登录"
      },
      component: LoginView
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    }
  ],
  history: createWebHistory()
});

let isInitRouter = false;
router.beforeEach( async (to, from, next) => {

  // if (to.name === "login-form" && auth.loggedIn()) {
  //   next({ name: "home" });
  // }
  const appUserStore = userStore();
  if (appUserStore.token !== undefined && appUserStore.token !== "") {
    if (to.name === "login-form") {
      next({name: "home"});
    } else {
      if (appUserStore.userInfo.id === undefined || appUserStore.userInfo.id === "") {
        const userInfo = await getUserInfo();
        console.warn(userInfo)
        if (userInfo) {
          appUserStore.setUserInfo(userInfo)
          appUserStore.setUserMenu(userInfo.menus)
          appUserStore.setUserPerms(userInfo.perms)
        } else {
          appUserStore.resetAuthInfo();
          next({name: "login-form"});
        }
      }
      if (!isInitRouter) {
        let menu = appUserStore.userMenu;
        let dynimcRoutes = generateRoutes(menu);
        appUserStore.setUserRoutes(dynimcRoutes)
        dynimcRoutes.forEach((value, index) => {
          console.log(value)
          router.addRoute(value)
        })
        isInitRouter = true;
        next ({ ...to, replace: true })
      }else {
        next();
      }

    }
  } else {
    if (to.name !== "login-form") {
      next({name: "login-form"});
    } else {
      next();
    }
  }
});

function generateRoutes(data) {
  const nodes = flattenTree({items : data, path : ''});
  const routes = [];
  for (const node of nodes.filter(n => isNotEmpty(n.component) &&  isNotEmpty(n.path))) {
    const route = {
      path: node.path,
      name: node.code,
      component: loadView (node.component)  ,  // Replace with your NodeView component
      meta: { title : node.title, icon: node.icon , requiresAuth: true, layout: defaultLayout}
    };
    routes.push(route);
  }
  console.error("routes",routes)
  return routes;
}

function  loadView(view) {
  let res;
  for (const path in modules) {
    const dir = path.split('views/')[1].split('.vue')[0];
    if (dir === view) {
      res = () => modules[path]();
    }
  }
  return res;
}

function flattenTree(node, result = []) {
  result.push(node);
  if (node.items && node.items.length > 0) {
    for (const childNode of node.items) {
      flattenTree(childNode, result);
    }
  }
  return result;
}

export default router;
