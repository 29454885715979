export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Examples",
    icon: "folder",
    items: [
      {
        text: "Profile",
        path: "/profile"
      },
      {
        text: "Tasks",
        path: "/tasks"
      },
      {
        text: "系统菜单",
        path: "/sysMenu"
      },{
        text: "系统用户",
        path: "/sysUser"
      }
    ]
  }
];
