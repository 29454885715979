<template>
  <div class="single-card-container">
    <dx-scroll-view class="with-footer single-card">
      <div class="dx-card content w-full h-full">
        <slot />
      </div>
    </dx-scroll-view>
  </div>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";
import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
  components: {
    DxScrollView
  },
  setup() {
    const route = useRoute();
    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      title,
      description
    }
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.single-card-container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%);
  box-sizing: border-box;

  :deep(.dx-scrollview-content) {
    display: flex;
    height: 100%;
    width: 100%;
  }
}

.single-card {
  flex: 1;
  width: 100%;
  height: 100%;

  .dx-card {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    display: flex;
    
    &.content {
      flex: 1;
      display: flex;
    }
  }
}

// 确保滚动视图正确工作
:deep(.dx-scrollable-wrapper) {
  width: 100%;
  height: 100%;
}

:deep(.dx-scrollable-container) {
  width: 100%;
  height: 100%;
}
</style>
