import { request } from "@/utils/request";

export const userLogin = (data) => {
    return request('/api/Jwt/Login', data, 'POST')
};

export const getUserInfo = () => {
    return request('/api/Jwt/UserInfo', '', 'GET')
};

// More API functions...
