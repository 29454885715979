<template>
  <div class="login-container">
    <div class="login-form-wrapper">
      <div class="login-card">
        <div class="login-header">
          <h1>YunDT</h1>
          <p>智能办公管理系统</p>
        </div>
        
        <div class="login-form">
          <dx-form ref="form" :form-data="formData" @submit="onSubmit">
            <dx-item
              data-field="username"
              editor-type="dxTextBox"
              :editor-options="{
                stylingMode: 'outlined',
                placeholder: '请输入登录账号',
                height: 48
              }"
            >
              <dx-required-rule message="请输入登录账号" />
              <dx-label :visible="false" />
            </dx-item>

            <dx-item
              data-field='password'
              editor-type='dxTextBox'
              :editor-options="{
                stylingMode: 'outlined',
                placeholder: '请输入登录密码',
                mode: 'password',
                height: 48
              }"
            >
              <dx-required-rule message="请输入登录密码" />
              <dx-label :visible="false" />
            </dx-item>

            <dx-button-item>
              <dx-button-options
                width="100%"
                height={48}
                type="default"
                :use-submit-behavior="true"
              >
                <template #default>
                  <div class="login-button-content" @click="onSubmit">
                    <dx-load-indicator
                      v-if="loading"
                      width="24px"
                      height="24px"
                      :visible="true"
                    />
                    <span v-if="!loading">登录</span>
                  </div>
                </template>
              </dx-button-options>
            </dx-button-item>
          </dx-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions
} from "devextreme-vue/form";
import notify from 'devextreme/ui/notify';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { userStore } from '@/store/user';
import { userLogin } from "@/api/auth";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const form = ref(null);

    const formData = reactive({
      username: "",
      password: ""
    });
    const loading = ref(false);

    async function onSubmit() {
      const { isValid } = await form.value.instance.validate();
      if (!isValid) return;

      loading.value = true;
      try {
        const { username, password } = formData;
        const result = await userLogin({ username, password });
        const userInfo = userStore();
        userInfo.setToken(result.token);
        
        if (result.token) {
          router.push("/home");
        } else {
          notify("账号或密码错误", "error", 2000);
        }
      } catch (error) {
        notify(error.message || "登录失败，请重试", "error", 2000);
      } finally {
        loading.value = false;
      }
    }

    return {
      form,
      formData,
      loading,
      onSubmit
    };
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxEmailRule,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions
  }
};
</script>

<style lang="scss">
.login-container {
  @apply relative w-full h-full;
  background: linear-gradient(135deg, #ff0000, #ff8000, #ffff00, #00ff00, #00ffff, #0000ff, #8000ff);
  background-size: 400% 400%;
  animation: rainbow-gradient 10s ease infinite;

  .login-form-wrapper {
    @apply w-full h-full flex items-center justify-center px-4;
  }

  .login-card {
    @apply w-full max-w-[420px] bg-white/80 backdrop-blur-xl rounded-3xl shadow-xl p-12;
  }

  .login-header {
    @apply text-center mb-12;

    h1 {
      @apply text-[2.5rem] font-light text-gray-900 tracking-tight;
    }

    p {
      @apply mt-3 text-sm font-medium text-gray-500;
    }
  }

  .login-form {
    :deep(.dx-texteditor) {
      @apply mb-6;

      &.dx-editor-outlined {
        @apply bg-white/60 border border-gray-200 rounded-xl transition-all duration-200;

        &:hover {
          @apply bg-white/80 border-gray-300;
        }

        &.dx-state-focused {
          @apply bg-white border-blue-500 ring-2 ring-blue-100;
        }
      }

      input {
        @apply text-gray-900 text-[15px] px-4;

        &::placeholder {
          @apply text-gray-400;
        }
      }
    }

    :deep(.dx-button) {
      @apply mt-8;

      .dx-button-content {
        @apply bg-gradient-to-r from-blue-600 to-indigo-600 rounded-xl text-white font-medium 
               shadow-lg shadow-blue-500/20 transition-all duration-200;

        &:hover {
          @apply from-blue-700 to-indigo-700 shadow-xl shadow-blue-500/30;
        }

        &:active {
          @apply shadow-md transform scale-[0.98];
        }
      }
    }

    .login-button-content {
      @apply flex items-center justify-center text-base;
    }

    :deep(.dx-loadindicator) {
      @apply text-white;
    }
  }
}

@keyframes rainbow-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
