import { createApp } from 'vue'
import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './style.css';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import zhMessages from "devextreme/localization/messages/zh.json";
import notify from "devextreme/ui/notify";
import { locale, loadMessages } from "devextreme/localization";
import { createStore } from './utils/devextremeUtil';
loadMessages(zhMessages);
locale(navigator.language);

config({ licenseKey });
const pinia = createPinia()
pinia.use(piniaPersist)
const app = createApp(App)
app.component('notify',notify)
app.config.globalProperties.$createStore = createStore
app.use(router)
app.use(pinia)
app.mount('#app')
