import { defineStore } from 'pinia'

export const userStore = defineStore('user', {
  state: () => {
    return { token: '',userInfo: { id: '',username: '',nickname: '',phone: '',email: ''},userMenu: [],userPerms: [],userRoutes: []}
  },
  // 也可以这样定义状态
  // state: () => ({ count: 0 })
  actions: {
    setToken(token) {
      this.token = token
    },
    setUserInfo(userInfo) {
      this.userInfo = userInfo
    },
    setUserMenu(userMenu) {
      this.userMenu = userMenu
    },
    setUserPerms(userPerms) {
      this.userPerms = userPerms
    },
    setUserRoutes(userRoutes) {
      this.userRoutes = userRoutes
    },
    resetAuthInfo() {
        this.token = ''
        this.userInfo = { id: '',username: '',nickname: '',tel: '',email: ''}
        this.userMenu = []
        this.userPerms = []
        this.userRoutes = []

    }
  },
  persist: {
    enabled: true // true 表示开启持久化保存
  }
})
