import CustomStore from 'devextreme/data/custom_store';
import {request} from './request';
import { createStore as tempStore } from 'devextreme-aspnet-data-nojquery';
import { userStore } from '../store/user';

export function createAspnetStore(entityName,key) {
  return tempStore({
    key: key,
    loadUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Lists`,
    insertUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Create`,
    updateUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Edit`,
    deleteUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Delete`,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        "Authorization": `Bearer ${userStore().token}`
      }
    },
  });
}

export function createAspnetStoreLoadParams(entityName, key, defaultFilter) {
  return tempStore({
    key: key,
    loadUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Lists`,
    insertUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Create`,
    updateUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Edit`,
    deleteUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Delete`,
    onBeforeSend: (method, ajaxOptions) => {
      // 解析当前 URL 中的查询参数
      const urlParams = new URLSearchParams(ajaxOptions.data);
      let currentFilter = urlParams.get('filter');
    
      // 如果存在 filter，将 defaultFilter 合并进去
      if (currentFilter) {
        currentFilter = JSON.parse(currentFilter);
        const combinedFilter = [currentFilter, 'and', defaultFilter];
        urlParams.set('filter', JSON.stringify(combinedFilter));
      } else {
        // 如果没有 filter，直接使用 defaultFilter
        urlParams.set('filter', JSON.stringify(defaultFilter));
      }
      
      ajaxOptions.data = urlParams.toString();
      ajaxOptions.headers = {
        "Authorization": `Bearer ${userStore().token}`
      }
    },
  });
}

export function createAspnetStoreOptions(entityName,key,loadParams) {
  return tempStore({
    key: key,
    loadUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Lists`,
    insertUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Create`,
    updateUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Edit`,
    deleteUrl: `${import.meta.env.VITE_API_URL}/api/${entityName}/Delete`,
    loadParams: loadParams,
    onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers = {
        "Authorization": `Bearer ${userStore().token}`
      }
    },
  });
}

export function createLoadStore(key, loadUrl) {
  return new CustomStore({
    key: key,
    load: function(loadOptions) {
      request(`${loadUrl}`,{values: parseParams(loadOptions)},"GET")
          .then(res => {
              console.warn(res)
              return new Promise((resolve, reject) => {
                  console.warn(res)
                  resolve(res.data);
              });
          }).catch(error => {
          return new Promise((resolve, reject) => {
              reject(error);
          });
      });
    },
  });
}

export function createStore(key, loadUrl, byKeyUrl, insertUrl, updateUrl, deleteUrl) {
  return new CustomStore({
    key: key,
    load: isNotEmpty(key)
     ? function(loadOptions) {
          console.warn(loadOptions)
          return new Promise(async (resolve, reject) => {
            const resp = await request(`${loadUrl}`,{values: parseParams(loadOptions)},"GET");
            resolve(resp.data);
          });
        } : undefined,
    insert: isNotEmpty(insertUrl)
      ? function(data) {
          return request(`${insertUrl}`,data,"POST");
        } : undefined,
    update: isNotEmpty(updateUrl)
       ? function(key, data) {
          data['id'] = key;
          return request(`${updateUrl}`,data,"PUT");
        } : undefined,
    remove: isNotEmpty(deleteUrl)
      ? function(key) {
          return request(`${deleteUrl}`,{id : key},"DELETE");
        } : undefined,
    byKey: isNotEmpty(byKeyUrl)
      ? function(key) {
          return new Promise(async (resolve, reject) => {
            const resp = await request(`${byKeyUrl}`, { id: key },"GET");
            resolve(resp.data);
          });
        }: undefined,
  });
}

export function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== '';
}

export function parseParams(loadOptions) {
  let params = '';
  [
    'skip',
    'take',
    'requireTotalCount',
    'requireGroupCount',
    'sort',
    'filter',
    'totalSummary',
    'group',
    'groupSummary',
    'searchExpr',
    'searchOperation',
    'searchValue'
  ].forEach(function(i) {
    if (i in loadOptions && isNotEmpty(loadOptions[i])) {
      params += `${i}=${JSON.stringify(loadOptions[i])}&`;
    }
  });
  params = params.slice(0, -1);
  return encodeURIComponent(encodeURIComponent(params));
}
